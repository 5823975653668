import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import PolicyPageTemplate from '../components/PolicyPageTemplate'
import Layout from '../components/Layout'
import config from '../../config'
import SE0 from '../components/SEO'

const PolicyPage = (props) => {
  const { data: { markdownRemark: post } } = props

  return (
    <Layout>
      <SE0
        title={post.frontmatter.title}
        meta_title={post.frontmatter.meta_title}
        meta_desc={post.frontmatter.meta_description}
        keywords={post.frontmatter.keywords}
        slug="/privacy-policy/"
        cover="/img/polityka-prywatnosci.svg"
        siteTitleAlt={config.siteTitleAlt}
        userName={config.userName}
        siteTitle={config.siteTitle}
        siteUrl={config.siteUrl}
        siteFBAppID={config.siteFBAppID}
        userTwitter={config.userTwitter}
        pathPrefix={config.pathPrefix}
      />
      <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>

      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://artopen.co/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Privacy policy",
        }]
      })}
      </script>

      </Helmet>
      <PolicyPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

PolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PolicyPage

export const policyPageQuery = graphql`
  query PolicyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta_title
        meta_description
      }
    }
  }
`
